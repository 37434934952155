import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage
  } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import {isMobile , isAndroid, isIOS} from 'react-device-detect';
import { link, linkWeb } from '../ultils/variabel';
import formatRupiah from '../ultils/hargaConverter';
import Pagination from './Pagination';
const ProductList = (props)=>{
    let mobile = {
        width: "10rem",
        height : "22rem",
        margin : "20px"
      }
  
      let another = {
        width : "12rem",
        height : "25rem",
        margin : "20px"
      }

    let result = props.result;
    let listItems;
    let pag = "";
    if(result.length === 0){
        listItems = <div className='display-4 mt-5' style={{ fontSize: "25px" }}>No Result</div>;
    }else{
        listItems = result.map((rslt, inx) => 
        <MDBCard className='mb-3 shadow linked' style={isMobile || isAndroid || isIOS ? mobile : another} onClick={()=>{window.location.href = linkWeb +  "product?p=" + rslt["uuid"];}} key={inx}>
            <MDBCardImage src={link +  rslt["link"]} className='rounded-top' fluid alt='...' />
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
            <MDBCardBody>
                <MDBCardTitle className='text-truncate' >{formatRupiah(rslt["harga"], 'Rp. ')}</MDBCardTitle>
                
                <MDBCardText className='text-truncate' style={{ display: "block", height : "40px" }}>
                    {rslt["nama"]}
                </MDBCardText>
                <Button variant="outline-primary" onClick={()=>{window.location.href = linkWeb +  "product?p=" + rslt["uuid"];}} >Buy</Button>
            </MDBCardBody>
        </MDBCard>
        )

        pag = (<Pagination
            limit={props.limit}
            index={props.index}
            setIndex={props.setIndex}
        />);
    }

    return (
        <div className='container pt-3 pb-5 mt-5 mb-5 shadow rounded'>
            <h1 className='container display-4 d-block' style={{ fontSize: "18px" }}>List Barang</h1>
            <div className='container mt-3 mb-5 d-flex justify-content-around flex-wrap'>
                {listItems}
            </div>
            <div className='d-flex container justify-content-around flex-wrap' style={ {width:"50%"} }>
                {pag}
            </div>
            
        </div>
    );
}

export default ProductList;