import React from 'react';

const Loading = (props)=>{
    return (<div className='container mb-5'>
        
        <div className="loading" style={{ height : props.height, width: props.width, overflow: "hidden"}}>
            <div className='slideLoading' style={{ height : "match-parent"}}></div>
        </div>

    
  </div>);
}

export default Loading;