import NavBarComponent from './components/NavBarComponents';
import { useSearchParams } from 'react-router-dom';
import Footer from './components/Footer';
import EditCategory1Component from './components/editCategory1Component';



function EditSubCategory() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("cs")
  return (
        <>
      <NavBarComponent />
      <EditCategory1Component 
        id={id}
      />
      <Footer/>
      </>
  );
}

export default EditSubCategory;
