import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { link } from "../ultils/variabel";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/esm/Button';

const EditProductComponent = (props)=> {
    const [nama, setNama] = useState("");
    const [harga, setHarga] = useState([])
    const [deskripsi, setDeskripsi] = useState("");
    const [info, setInfo] = useState("")
    const [tipe1, setTipe1] = useState([])
    const [tipe2, setTipe2] = useState([])
    const [jumTipe, setJumTipe] = useState(0);
    const [listTipe , setListTipe] = useState([]);
    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState([<option value={"NoResult"} key={"NoResult"}>NoResult</option>]);
    const [penentu, setPenentu] = useState(false);
    const [merek, setMerek] = useState("");
    const [flash, setFlash] = useState([]);
    const [dis, setDis] = useState(false);
    const [idP, setIdP] = useState([])
    const [del, setDel] = useState([])
    const navigate = useNavigate();
    const cookies = new Cookies();
    const id = props.id
    let token = cookies.get("token")
    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time))
    }
    

    useEffect(()=>{
        async function callApiCat(){
            let token = cookies.get("token")
            if(token){
                let dta = {
                    token : token
                }
                await axios.put(link+'admin/subCategory2', dta).then((data) =>{
                    if(data.status === 200){
                        let temp = data.data.map((result, index) => <option value={result.nama} key={"keyOp" + index}>{result.nama}</option>)
                        setCategoryList(temp)
                    }

                    console.log(data)
                })
                

                await axios.get(link + "admin/product/" + id).then( data => {
                    let temp = data["data"];
                    let tp1 = [];
                    let tp2 = [];
                    let hrg = [];
                    let fl = [];
                    let ids = []
                    temp.forEach((element) => {
                        
                        setMerek(element["merek"]);
                        setDeskripsi(element["deskripsi"]);
                        setInfo(element["info"])
                        setCategory(element["category"]);
                        setNama(element["nama"]);
                        tp1.push(element["tipe1"]);
                        tp2.push(element["tipe2"]);
                        hrg.push(element["harga"]);
                        fl.push(element["link"]);
                        ids.push(element["id"])
                    });
                    setIdP(ids)
                    setTipe1(tp1);
                    setTipe2(tp2);
                    setHarga(hrg);
                    setJumTipe(temp.length);
                });

            }else{
                navigate("/")
            }
            
        }
        callApiCat();
    }, [])

    useEffect(()=>{
        let tp1 = [];
        let tp2 = [];
        let hr = [];
        let temp = [];
        let tmidP = []
        for (let index = 0; index < jumTipe; index++) {
            tp1.push(tipe1[index])
            tp2.push(tipe2[index])
            hr.push(harga[index])
            tmidP.push(idP[index])
            temp.push((
                <div
                key={"dvP" + index}
                >
                    <hr 
                key={"hr" + index}
                className="bg-danger border-2 border-top border-default"></hr>
                    <InputGroup className="mb-3" key={"IG1" + index}>
                        <input type="hidden" name={"idP"+index} value={idP[index]} />
                        
                        <InputGroup.Text>Tipe  </InputGroup.Text>
                        <Form.Control 
                        key={"tp1" + index}
                        required={true}
                        defaultValue={tipe1[index]}
                        name={"tipe1"+index}
                        aria-label="First name" 
                        />
                        <InputGroup.Text>Jenis  </InputGroup.Text>
                        <Form.Control 
                        key={"tp2" + index}
                        required={true} 
                        name={"tipe2"+index}
                        defaultValue={tipe2[index]}
                        aria-label="Last name" 
                        />
                    
                </InputGroup>
                <InputGroup
                key={"IG2" + index}
                className='mb-3'
                >
                <InputGroup.Text>Rp </InputGroup.Text>
                    <Form.Control 
                    key={"hrg1" + index}
                    defaultValue={harga[index]}
                    name={"harga"+index}
                    required={true} />
                    <InputGroup.Text>,00</InputGroup.Text>

                    <Form.Control
                        type="file" 
                        name={"file"+index}
                        key={"fl1" + index}
                        required={!idP[index]}/>
                </InputGroup>
            </div>
            ))
        }
        setTipe1(tp1)
        setTipe2(tp2)
        setHarga(hr)
        setListTipe(temp)
        setIdP(tmidP)
    }, [jumTipe])
    
  return (
    <div className='container mt-5 mb-5'>
        <h1 className='display-4 text-center mb-3' style={{ fontSize: "40px" }}>Edit Product</h1>
        
        {penentu ? flash : ""}
    <Form action={link + "admin/editProduct"} method="post" encType='multipart/form-data'>
    <input type="hidden" name={"uuid"} value={id} />
    <input type="hidden" name={"token"} value={token} />
    <input type="hidden" name="jumlah" value={jumTipe} />
    <input type="hidden" name='del' value={del} />
        <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
                Nama Product
            </InputGroup.Text>
            <Form.Control
            onChange={(e)=> {setNama(e.target.value)}}
            required={true}
            value={nama}
            name="nama"
            placeholder="namaBarang"
            aria-label="Nama Product"
            aria-describedby="basic-addon1"
            />
        </InputGroup>
        

       

        

    <Form.Select
    value={category}
    onChange={(e)=>{setCategory(e.target.value)}} name="category" className='mb-3' aria-label="Pilih Category">
        <option>Pilih Category</option>
        {categoryList}
    </Form.Select>
    <InputGroup
        
        className='mb-3'>
            <InputGroup.Text>Merek</InputGroup.Text>
            <Form.Control 
                name="merek"
                value={merek}
                onChange={(e)=>{
                    setMerek(e.target.value)
                }}
            />
        </InputGroup>
        <InputGroup
        
        className='mb-3'>
            <InputGroup.Text>Deskripsi</InputGroup.Text>
            <Form.Control 
            value={deskripsi}
            name="deskripsi"
            onChange={(e)=>{
                setDeskripsi(e.target.value)
            }}
            />
        </InputGroup>
        <InputGroup className='mb-3'>
            <InputGroup.Text>Info</InputGroup.Text>
            <Form.Control 
            value={info}
            name="info"
            onChange={(e)=>{
                setInfo(e.target.value)
            }}
            />
        </InputGroup>
        {listTipe}
        <div 
        className='mb-3 linked rounded'
        onClick={()=> {
            setJumTipe(jumTipe - 1);
            let temp = listTipe.pop();
            let tmp = del;
            tmp.push(temp.props.children[1].props.children[0].props.value)
            setDel(tmp)
        }} 
        style={
            {
                background : "#f02724",
                textAlign : "center"
            }
        }>-</div>
        <div 
        className='mb-3 linked rounded'
        onClick={()=> {setJumTipe(jumTipe + 1)}} 
        style={
            {
                background : "#dfdfdf",
                textAlign : "center"
            }
        }>+</div>
        <Button 
        variant="primary" 
        type="submit"
        disabled={dis}
        >
            Submit
        </Button>
      </Form>
    </div>
  );
}

export default EditProductComponent;