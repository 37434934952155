import Dropdown from 'react-bootstrap/Dropdown';

function DropDown(props) {
    const judul = props.judul;
    let result = "";
    const hasil = props.result;
    
    result = []
    
    for (let index = 0; index < hasil.length; index++) {
      const element = hasil[index];
      result.push(<Dropdown.Item 
          className='text-capitalize text-truncate' 
          key={index} 
          onClick={()=> clickToggle(element.nama)}>{element.nama}</Dropdown.Item>);
    }

    function clickToggle(category){

        if(props.event){
            props.event("All")
        }

        props.changeCategory(category)
    }

  return (
    <Dropdown className='mt-3 mb-3 mx-3'>
      <Dropdown.Toggle className='text-truncate text-capitalize' style={ { minWidth:"200px", width:"250px", backgroundColor: "#FFFFFF", color:"black" }}  id="dropdown-basic">
        {props.awal + " - " + judul}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth:"200px", width:"250px" }}>
        <Dropdown.Item className='text-truncate' onClick={()=> clickToggle("All")}>All</Dropdown.Item>
        {result}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropDown;