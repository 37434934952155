import formatRupiah from '../../ultils/hargaConverter';


const Info = (props)=>{
    
    return (
        
            <><p className='display-4' style={{ fontSize: "50px" }}>{props.nama}</p><p className='display-4' style={{ fontSize: "30px" }}>{formatRupiah(props.harga)}</p><p className='display-4 text-capitalize' style={{ fontSize: "25px" }}> Kategori : {props.category}</p></>

           
        
    )
}

export default Info;