import NavBarComponent from './components/NavBarComponents';
import Footer from './components/Footer';
import InputCategory from './components/inputCategory';


function AddCategory() {
  return (
        <>
        <NavBarComponent />
        <InputCategory/>
        <Footer/>
      </>
  );
}

export default AddCategory;
