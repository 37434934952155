import React from "react"
const Pagination = (props)=>{

    const {limit, index, setIndex} = props;
    let items = [];
    for (let idx = 1; idx <= limit; idx++) {
        if(index != 1 && idx == 1){
            items.push(<span key={"kurang"} className='linked' onClick={()=> setIndex(index - 1)}>&lt;</span>)
        }

        

        items.push(<span key={idx} className='linked' onClick={()=>{setIndex(idx)}} >{idx}</span>)

        if(index != limit && idx == limit){
            items.push(<span key={"tambah"} className='linked' onClick={()=>{setIndex(index + 1)}}>&gt;</span>)
        }

    }

    return (<>
        {items}
    </>)
}

export default Pagination;