import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import DisplayProduct from "./DisplayProduct";
import Products from "./Products";
import Login from "./components/Login";
import AddProducts from "./addProduct";
import AddCategory from "./addCategory";
import EditProduct from "./editProduct";
import Laporan from "./statistic";
import EditCategory from "./editCategory";
import EditSubCategory from "./editSubCategory";
import SeeMember from "./Membership";
function App() {
  return (
    
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/product" element={<DisplayProduct />}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/addProduct" element={<AddProducts/>}/>
          <Route path="/addCategory" element={<AddCategory/>}/>
          <Route path="/editProduct" element={<EditProduct />}/>
          <Route path="/statistics" element={<Laporan />}/>
          <Route path="/editCategory" element={<EditCategory />}/>
          <Route path="/editCategory1" element={<EditSubCategory />}/>
          <Route path="/membership" element={<SeeMember/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
