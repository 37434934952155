import Footer from './components/Footer';
import MembershipComponent from './components/MembershipComponents';
import NavBarComponent from './components/NavBarComponents';
function SeeMember() {
  return (
      <>
        <NavBarComponent />
        <MembershipComponent />
        <Footer/>
      </>
  );
}

export default SeeMember;
