import CarouselPromo from './components/CarouselBanner';
import Footer from './components/Footer';
import Category from './components/kategori';
import NavBarComponent from './components/NavBarComponents';
import Product from './components/Product';

function Home() {
  return (
    
      <>
        <NavBarComponent />
        <CarouselPromo />
        <Category />
        <Product />
        <Footer/>
      </>
  );
}

export default Home;
