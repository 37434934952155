import NavBarComponent from './components/NavBarComponents';
import { useSearchParams } from 'react-router-dom';
import Footer from './components/Footer';
import EditProductComponent from './components/editProduct';


function EditProduct() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("p")
  return (
        <>
      <NavBarComponent />
      <EditProductComponent 
      id={id}
      />
      <Footer/>
      </>
  );
}

export default EditProduct;
