import React, { useEffect } from 'react';
import { link } from '../ultils/variabel';
import Deskripsi from './utils/deskripsi';
import ImageSelling from './utils/imageSelling';
import Info from './utils/info';
import Tipe from './utils/tipe';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
import sleep from '../ultils/sleep';
const ProductDisplay = (props) =>{
    let value = props.all[props.index]
    const cookies = new Cookies();
    let token = cookies.get("token")
    if(!value){
        return null;
    }
    

    async function updateActive(){
        let active = value.active == 1 ? 0 : 1;
        let data = {
            id : props.id,
            active : active,
            token : token
        }
        
        await axios.put(link + "admin/updateProduct", data).then(data =>{
            if(data["data"] == "berhasil"){
                Swal.fire(
                    'Success',
                    'Data Berhasil Di Ubah',
                    'success'
                  )
                  sleep(1500).then(()=>{
                    window.location.reload(true)
                 })
            }
        });
    }
    return (
        <>
            <ImageSelling link={link + value["link"]}/>
            <div className='container'>
                <Info 
                nama={value["nama"]} 
                harga={value["harga"]}
                category={value["category"]}
                />

                
                <Tipe 
                    tempTipe = {props.tempTipe}
                    setIndex={props.setIndex}
                    index={props.index}
                />
                

                <Deskripsi
                info={value["info"]}
                deskripsi={value["deskripsi"]}
                />
                <div className='mb-5'>
                <Button
                style={{ 
                    marginRight : "15px"
                 }}
                variant={value.active == 1 ? "danger" : "success"}
                onClick={updateActive}
                >
                    {value.active == 1 ? "non active" : "active"}
                </Button>
                <Button onClick={()=>{
                    window.location.href = "/editProduct?p=" + props.id
                }} variant='primary' className='ml-5'>edit</Button>
                </div>
                
                
            </div>
        </>
   )
}
export default ProductDisplay;