import React, { useState , useEffect } from 'react';
import ListCategory from './utils/kategoryList';
import Loading from './utils/loading';
import {link} from '../ultils/variabel';
import axios from 'axios';


const Category = () => {
   
    const [linkBanner, setLinkBanner] = useState(<Loading width="100%" height="50px" />);


    useEffect(()=>{
        async function callAPI() {
            const temp = await axios.get(link+"admin/category");
            setLinkBanner(<ListCategory list={temp["data"]}/>);
          }
          callAPI();
    }, []);
      

    return ( 
        <div className='kategori pb-1 pt-3'>
            <div className='mb-3 pb-1 shadow container'>
                <h1 className='text-center Judul mb-2'>KATEGORI</h1>
                {linkBanner}
            </div>
        </div>
     );
}

export default Category;