import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { link } from '../ultils/variabel';
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";

const Statistic = () => {

    const [label, setLabel] = useState([]);
    const [dta, setData] = useState([]);

    useEffect(() => {
        async function callAPI() {
            await axios.get(link + "admin/statistict").then(result =>
                {
                    
                    if(result.status == 200){
                        let dataSet = [];
                        let labels = [];
                        result.data.map((rslt)=>{
                            dataSet.push(rslt.jumlah);
                            labels.push(rslt.tanggal.split("T")[0])
                        })
                        console.log(dataSet)
                        console.log(labels)
                        setData(dataSet);
                        setLabel(labels);
                    }
                    
                } );
        }
        callAPI();
    }, [])
  const data = {
    labels: label,
    datasets: [
      {
        label: "Jumlah Pengunjung",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: dta,
      },
    ],
  };

  return (
    <div className='container'>
      <Bar data={data} />
    </div>
  );
};
export default Statistic;