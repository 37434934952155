import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { link } from '../../ultils/variabel';

const ListCategory = (props)=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };

    const listKategory = props.list.map((rslt, inx) => 

       
        <Card style={{  width: '4rem' }} key={inx}  className='border-0 linked' onClick={()=> window.location.href = "editCategory?c=" + rslt["namaCategory"]}>
            <Card.Img variant="top" src={link + rslt["link"]} />
            <p className='text-center' style={{ fontSize: "12px", fontWeight: "300" }} key={inx}>{rslt["namaCategory"].toUpperCase()}</p>
        </Card>
    )
    return (<Carousel 
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        infinite={true}
        centerMode={true}
        >
            {listKategory}
        </Carousel>);
};

export default ListCategory;