import NavBarComponent from './components/NavBarComponents';
import Footer from './components/Footer';
import Statistic from './components/Statistict';


function Laporan() {
  return (
        <>
      <NavBarComponent />
      <Statistic/>
      <Footer/>
      </>
  );
}

export default Laporan;
