import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { link } from "../ultils/variabel";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/esm/Button';

const InputProduct = ()=> {
    const [nama, setNama] = useState("");
    const [harga, setHarga] = useState([])
    const [file, setFile] = useState([]);
    const [deskripsi, setDeskripsi] = useState("");
    const [info, setInfo] = useState("")
    const [tipe1, setTipe1] = useState([])
    const [tipe2, setTipe2] = useState([])
    const [jumTipe, setJumTipe] = useState(1);
    const [listTipe , setListTipe] = useState([]);
    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState([<option value={"NoResult"} key={"NoResult"}>NoResult</option>]);
    const [penentu, setPenentu] = useState(false);
    const [merek, setMerek] = useState("");
    const [flash, setFlash] = useState([]);
    const [dis, setDis] = useState(false);
    const navigate = useNavigate();
    const cookies = new Cookies();

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time))
    }
    async function submit(event){
        
        let token = cookies.get("token")
        
        
        if(token){
            let data = {
                nama : nama,
                file : file,
                category : category,
                deskripsi : deskripsi,
                info : info,
                tipe1: tipe1,
                tipe2 : tipe2,
                harga : harga,
                token : token,
                jum : jumTipe,
                merek : merek
            }
            event.preventDefault();
            try {
                setDis(true)
                await axios.put(link+"admin/addProduct", data, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }).then((data) =>{
                    if(data.status === 200){
                        sleep(2000).then(()=>{
                            window.location.reload(true)
                         })
                         let tmp = [];
                         tmp.push((<div key={"flash"} className="alert alert-success alert-dismissible fade show" role="alert">
                         <strong>Data Berhasil DI tambah</strong>
                         <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                     </div>))
                         setFlash(tmp)
                        setPenentu(true);
                    
                    }
                  })
            } catch (error) {
                setDis(false)
                event.preventDefault();
                console.log(error)
                let tmp = [];
                tmp.push((<div key={"flash"} className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Data Tidak Bisa Tertambah, Mohon Coba Lagi</strong>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>))
                setFlash(tmp)
                    setPenentu(true)
            }
            
        }

    }
    async function callApiCat(){
        let token = cookies.get("token")
        if(token){
            let dta = {
                token : token
            }
            await axios.get(link+'admin/subCategory2').then((data) =>{
                if(data.status === 200){
                    let temp = data.data.map((result, index) => <option value={result.nama} key={"keyOp" + index}>{result.nama}</option>)
                    setCategoryList(temp)
                }
            })
        }else{
            navigate("/")
        }
        
    }
    callApiCat();


    useEffect(()=>{
        let tp1 = tipe1;
        let tp2 = tipe2;
        let hr = harga;
        let temp = [...listTipe];
        let fl = file;
        for (let index = jumTipe-1; index < jumTipe; index++) {
            tp1.push("")
            tp2.push("")
            fl.push("")
            hr.push("")
            temp.push((
                <div
                key={"dv" + index}
                >
                    <hr 
                key={"hr" + index}
                className="bg-danger border-2 border-top border-default"></hr>
                    <InputGroup className="mb-3" key={"IG1" + index}>
                        <InputGroup.Text>Tipe  </InputGroup.Text>
                        <Form.Control 
                        key={"tp1" + index}
                        required={true}
                        onChange={(e)=>{
                            let temp = tipe1
                            temp[index] = e.target.value;
                            setTipe1(temp)
                            // console.log("tipe1 : ", tipe1 )
                            // console.log("tipe2 : ", tipe2 )
                            // console.log("harga :  ", harga )
                        }}
                        aria-label="First name" 
                        />
                        <InputGroup.Text>Jenis  </InputGroup.Text>
                        <Form.Control 
                        key={"tp2" + index}
                        required={true} 
                        onChange={(e)=>{let temp = tipe2
                            temp[index] = e.target.value;
                            setTipe2(temp)
                        
                            // console.log("tipe1 : ", tipe1 )
                            // console.log("tipe2 : ", tipe2 )
                            // console.log("harga :  ", harga )
                        }}
                        aria-label="Last name" 
                        />
                    
                </InputGroup>
                <InputGroup
                key={"IG2" + index}
                className='mb-3'
                >
                <InputGroup.Text>Rp </InputGroup.Text>
                    <Form.Control 
                    key={"hrg1" + index}
                    onChange={(e)=>{
                        let temp = harga
                        temp[index] = e.target.value;
                        setHarga(temp)
                    
                        // console.log("tipe1 : ", tipe1 )
                        // console.log("tipe2 : ", tipe2 )
                        // console.log("harga :  ", harga )
                    }}
                    required={true} />
                    <InputGroup.Text>,00</InputGroup.Text>

                    <Form.Control
                        type="file" 
                        key={"fl1" + index}
                        required={true}
                        onChange={(e)=>{
                            let temp = file
                            temp[index] = e.target.files[0]
                            setFile(temp)
                            
                        }} />
                </InputGroup>
                
            </div>
            ))
        }
        setTipe1(tp1)
        setTipe2(tp2)
        setHarga(hr)
        setListTipe([...temp])
    }, [jumTipe])

  return (
    <div className='container mt-5 mb-5'>
        <h1 className='display-4 text-center mb-3' style={{ fontSize: "40px" }}>Add Product</h1>
        
        {penentu ? flash : ""}
    <Form onSubmit={submit}>
        <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
                Nama Product
            </InputGroup.Text>
            <Form.Control
            onChange={(e)=> {setNama(e.target.value)}}
            required={true}
            placeholder="namaBarang"
            aria-label="Nama Product"
            aria-describedby="basic-addon1"
            />
        </InputGroup>
        

       

        

    <Form.Select onChange={(e)=>{setCategory(e.target.value)}}  className='mb-3' aria-label="Pilih Category">
        <option>Pilih Category</option>
        {categoryList}
    </Form.Select>
    <InputGroup
        
        className='mb-3'>
            <InputGroup.Text>Merek</InputGroup.Text>
            <Form.Control 
            onChange={(e)=>{
                setMerek(e.target.value)
            }}
            />
        </InputGroup>
        <InputGroup
        
        className='mb-3'>
            <InputGroup.Text>Deskripsi</InputGroup.Text>
            <Form.Control 
            onChange={(e)=>{
                setDeskripsi(e.target.value)
            }}
            />
        </InputGroup>
        <InputGroup className='mb-3'>
            <InputGroup.Text>Info</InputGroup.Text>
            <Form.Control 
            onChange={(e)=>{
                setInfo(e.target.value)
            }}
            />
        </InputGroup>
        {listTipe}
        <div 
        className='mb-3 linked rounded'
        onClick={()=> {setJumTipe(jumTipe + 1)}} 
        style={
            {
                background : "#dfdfdf",
                textAlign : "center"
            }
        }>+</div>
        <Button 
        variant="primary" 
        type="submit"
        disabled={dis}
        >
            Submit
        </Button>
      </Form>
    </div>
  );
}

export default InputProduct;