import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {link} from '../ultils/variabel';
import ImageBanner from './utils/imageBanner';
import Loading from "./utils/loading"


const CarouselPromo = () =>{

    

    const [linkBanner, setLinkBanner] = useState(<Loading width="100%" height="440px" />);

    useEffect(() => {
      async function callAPI() {
        const temp = await axios.get(link+"banner");
        setLinkBanner(<ImageBanner link={temp["data"]}/>);
      }
      callAPI();
    }, [])
    return( linkBanner );
}

export default CarouselPromo;


