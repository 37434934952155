import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../LOGO DEPO.jpg';
import axios from "axios";
import { link } from "../ultils/variabel";
import Cookies from "universal-cookie";
import { linkWeb } from "../ultils/variabel";

const NavBarComponent =  () => {

    const [search , setSearch] = useState("");

    const [listItems, setListItems] = useState([]);

    const [display, setDisplay] = useState(false);

    const cookies = new Cookies();

    let auth = "";

    const token = cookies.get("token");
    if(token){
        auth = (<NavDropdown.Item href="#" onClick={logOut}>
        Logout
    </NavDropdown.Item>);
    }else{
        auth = (
            <NavDropdown.Item href="/">
                Login
            </NavDropdown.Item>
        )
    }
    



    async function callAPI() {
        const temp = await axios.post(link+"search", {text : search});
        setListItems(temp.data)
    }
    useEffect( ()=>{
        callAPI();
    }, [search])

    function clickSearch() {
        window.location.href = '/products?query='+search;
    }

    function logOut(){
        cookies.remove('token')
        window.location.href = "/"    
    }

    return ( 
        <Navbar bg="white" expand="lg" className="shadow sticky-top">
            <Container>
                <Navbar.Brand href={linkWeb + 'home'}>
                    <img src={logo} alt="logo" style={{ width: '35px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link href={linkWeb + 'home'}>Home</Nav.Link>
                        <Nav.Link href="/products">Products</Nav.Link>
                        <NavDropdown title="Admin" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/membership">
                                See Membership
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/statistics">
                                Statistics
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/addProduct">
                                Add Product
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/addCategory">
                                Add Category
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {
                                auth
                            }
                            
                        </NavDropdown>
                    </Nav>
                <Form className="d-flex">
                    <div className="position-relative container">
                        <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                        onChange={(v)=> {setSearch(v.target.value)}}
                        onClick={() => {setDisplay(!display)}}
                        value={search}
                        />

                        {display && (
                            <div className="position-absolute bg-white rounded" style={{ width:"90%" }}>
                            <ul className="list-group">
                                {
                                    listItems.map((v, i) =>{
                                        return <li className="list-group-item list-group-item-action linked" key={i} onClick={()=>{
                                            setSearch(v.nama);
                                            setDisplay(!display)
                                        }} >{v.nama}</li>
                                    })
                                }
                            </ul>
                        </div>
                        )}
                        
                    </div>
                    
                    <Button variant="outline-success" onClick={clickSearch}>Search</Button>
                </Form>
                
                </Navbar.Collapse>
            </Container>
        </Navbar>
     );
}

export default NavBarComponent;