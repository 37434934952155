import React from 'react';
const ImageSelling = (props)=>{
    return(
        <div className='container d-flex align-items-center justify-content-center'>
        <div className='displayImage mt-3 mb-3 border'>
            <img 
            src={props.link} 
            style={{ width: "100%", height: "100%" }} 
            />
        </div>
    </div>
    );
}

export default ImageSelling;