import React from 'react';
import Carousel from "react-multi-carousel";
import Button from 'react-bootstrap/Button';
import "react-multi-carousel/lib/styles.css";
import formatRupiah from '../../ultils/hargaConverter';
import {isMobile} from 'react-device-detect';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage
  } from 'mdb-react-ui-kit';
import { linkWeb, link } from '../../ultils/variabel';
  
const ListProduct = (props)=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
        

    let mobile = {
      width: "10rem",
      height : "22rem"
    }

    let another = {
      width : "12rem",
      height : "25rem"
    }
    const listItems = props.list.map((rslt, inx) => 
          <MDBCard className='mb-3 shadow linked' style={isMobile ? mobile : another} onClick={()=>{window.location.href = linkWeb +  "product?p=" + rslt["uuid"];}} key={inx}>
              <MDBCardImage src={link +  rslt["link"]} className='rounded-top' fluid alt='...' />
              <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
              <MDBCardBody>
                  <MDBCardTitle className='text-truncate' >{formatRupiah(rslt["harga"], 'Rp. ')}</MDBCardTitle>
                  
                  <MDBCardText className='text-truncate' style={{ display: "block", height : "40px" }}>
                      {rslt["nama"]}
                  </MDBCardText>
                  <Button variant="outline-primary" onClick={()=>{window.location.href = linkWeb +  "product?p=" + rslt["uuid"];}} >Buy</Button>
              </MDBCardBody>
          </MDBCard>
    )
    return (
    <Carousel 
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        infinite={true}
        >
        {listItems}
        </Carousel>)
}

export default ListProduct;