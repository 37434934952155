const Tipe = (props)=>{
    let temp = props;
    if(!temp){
        return null;
    }
    
    
    let tipe1 = temp.tempTipe;
    if(!temp.tempTipe){
        return null;
    }
    
    let tempTipe1 = tipe1[temp.index]["tipe1"];
    let tempTipe2 = tipe1[temp.index]["tipe2"];

    let tempTipe = {};
    tipe1.forEach(element =>{
        if(!tempTipe[element["tipe1"]]){
            tempTipe[element["tipe1"]] = [];
        }
        tempTipe[element["tipe1"]].push(element["tipe2"])
    })

    
 
    function match(){
        for (let index = 0; index < tipe1.length; index++) {
            if(tipe1[index]["tipe1"] === tempTipe1){
                props.setIndex(tipe1[index].index);
                break;
            }
        }
    }
    function matchTipe2(){
        for (let index = 0; index < tipe1.length; index++) {
            if(tipe1[index]["tipe1"] === tempTipe1 && tipe1[index]["tipe2"] === tempTipe2){
                props.setIndex(tipe1[index].index);
                break;
            }
        }
    }
    const keys = Object.keys(tempTipe);

    let render = keys.map((rslt)=>
        <span 
        className={rslt === tempTipe1 ? "text-decoration-underline display-4 mx-3 linked" : "display-4 mx-3 linked"} 
        style={{  fontSize: "14px" }} 
        onClick={()=>{
            tempTipe1 = rslt
            match();
        }}
        key={rslt}>
            {rslt}
            </span>
    )
    let variasiRender = tempTipe[tempTipe1].map((rslt) =>
        <span 
        className={rslt === tempTipe2 ? "text-decoration-underline display-4 mx-3 linked" : "display-4 mx-3 linked"} 
        style={{  fontSize: "14px" }} 
        onClick={()=>{
            tempTipe2 = rslt
            matchTipe2();
        }}
        key={rslt}>
            {rslt}
            </span>
    )
    return (
            <>
                <p className="display-4" style={{  fontSize: "18px" }} >Tipe: </p> 
                <p>
                {render}
                </p>
                <p className="display-4" style={{  fontSize: "18px" }} >Variasi: </p> 
                {variasiRender}
            </>
        
    )
}

export default Tipe;