import NavBarComponent from './components/NavBarComponents';
import Footer from './components/Footer';
import InputProduct from './components/inputProduct';


function AddProducts() {
  return (
        <>
      <NavBarComponent />
      <InputProduct />
      <Footer/>
      </>
  );
}

export default AddProducts;
