import axios from 'axios';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { link } from '../ultils/variabel';
import { Container, Row, Col, Table, InputGroup, FormControl, Button } from "react-bootstrap";

function MembershipComponent() {
    
  const [members , setMembers] = useState([]);
  const [output, setOutput] = useState([])
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [searchKeyword, setSearchKeyword] = useState('');

  function handleChange(event) {
    setSearchKeyword(event.target.value);
    setOutput(MemberList())
  }

  function MemberList() {
    // ...
    const filteredMembers = members.filter((member) => {
      const lowerSearchKeyword = searchKeyword.toLowerCase();
      return (
        member.nama.toLowerCase().includes(lowerSearchKeyword) ||
        member.email.toLowerCase().includes(lowerSearchKeyword) ||
        member.alamat.toLowerCase().includes(lowerSearchKeyword)
      );
    });
    return filteredMembers;
  }

  useEffect(()=>{
    async function callAPI(){
      let mmbr = [];
      
      await axios.post(link + "admin/membership", {token}).then(result =>{
        if(result.status === 200){
          mmbr = result.data;
          setMembers([...mmbr]);
          setOutput([...mmbr])
        }
      });
    }

    callAPI();
  }, [])

  return (
    <>
    <Container className="mt-5">
      <Row className="mt-3">
        <Col>
          <div className="mb-3">
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Search by name, email, or address"
                value={searchKeyword}
                onChange={handleChange}
              />
              <Button variant="outline-secondary">Search</Button>
            </InputGroup>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {output.map((member, index) => (
                <tr key={index}>
                  <td>{member.nama}</td>
                  <td>{member.email}</td>
                  <td>{member.alamat}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      </Container>
      {/* <Container className="mt-5">
      <Row>
        <Col>
          <h2>Member List</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          
        </Col>
      </Row>
    </Container> */}
    </>
  );
}

export default MembershipComponent;
