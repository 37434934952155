import React, { useState , useEffect } from 'react';
import Loading from './utils/loading';
import {link} from '../ultils/variabel';
import axios from 'axios';
import ListProduct from './utils/productList';


const Product = () => {
    
    const [linkBanner, setLinkBanner] = useState(<Loading width="100%" height="200px" />);
      
    useEffect(() => {
        async function callAPI() {
          const temp = await axios.get(link+"products");
          setLinkBanner(<ListProduct list={temp["data"]} />);
        }
        callAPI();
      }, [])
    return ( 
        <div className='pb-1 pt-3'>
            <div className= 'mb-3 shadow container rounded'>
                <h1 className='text-center Judul mb-2'>Product</h1>
                {linkBanner}
            </div>
        </div>
     );
}

export default Product;