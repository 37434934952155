import React from "react"
import { useState } from "react";
import axios from 'axios';
import { link } from "../ultils/variabel";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const Login = ()=> {
  const navigate = useNavigate();
  const cookies = new Cookies();

  useState(()=>{
    let token = cookies.get("token");
    if(token){
      window.location.href = '/home'
    }
  }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false)
    async function login(event){
        let data = {
            username : username,
            password : password
        }
        event.preventDefault();
        try {
          await axios.post(link + 'admin/login', data).then((data)=>{
            if(data.status === 200){
              cookies.set("token", data.data.token, {maxAge : 3600})
            
              navigate('/home')
            }
          })
        } catch (error) {
          setError(true);
        }
        

    }

    return(<div className="Auth-form-container">
    <form className="Auth-form" onSubmit={(e)=> login(e)}>
      <div className="Auth-form-content">
        <h3 className="Auth-form-title">Sign In</h3>
        <div className="form-group mt-3">
          <label>Email address</label>
          <input
            type="text"
            className="form-control mt-1"
            value={username}
            onChange={(e) =>{setUsername(e.target.value)}}
            placeholder="Enter username"
          />
        </div>
        <div className="form-group mt-3">
          <label>Password</label>
          <input
            type="password"
            className="form-control mt-1"
            value={password}
            onChange={(e) =>{setPassword(e.target.value)}}
            placeholder="Enter password"
          />
        </div>
        {error ?  <div className="d-grid gap-2 mt-2">
          <p style={{  color: "red" }}>Username / password salah</p>
        </div> : ""}
       
        <div className="d-grid gap-2 mt-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
        {/* <p className="forgot-password text-right mt-2">
          Forgot <a href="#">password?</a>
        </p> */}
      </div>
    </form>
  </div>)
}

export default Login;