import { useSearchParams } from 'react-router-dom';
import ProductDisplay from './components/ProductDisplay';
import axios from 'axios';
import { link } from './ultils/variabel';
import { useEffect, useState } from 'react';
import NavBarComponent from './components/NavBarComponents';
import Footer from './components/Footer';

function DisplayProduct() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("p")
    const [value, setValue] = useState({"id" : 0, "nama" : "Unknow", "harga":"Unknow", "link" : "", "info" : "", "deskripsi" : ""});
    const [all, setAll] = useState([]) 
    const [index, setIndex] = useState(0);
    const [tempTipe, setTempTipe] = useState([]);

    useEffect(() => {
        async function callAPI(){
            await axios.get(link + "admin/product/" + id).then( data => {
                let i = -1;
                data["data"].forEach(element => {
                    i++;
                    setValue(element);
                    all.push(element);
                    tempTipe.push({
                        "tipe1" : element.tipe1,
                        "tipe2" : element.tipe2,
                        "index" : i
                    })
                });
                
            });
        }
        callAPI();
    }, []);
    
  return (
      <>
      <NavBarComponent />
        <ProductDisplay 
            value={value} 
            all={all}
            setIndex={setIndex}
            index={index}
            tempTipe={tempTipe}
            id={id}
        />
        <Footer/>
      </>
  );
}

export default DisplayProduct;
