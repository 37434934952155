import NavBarComponent from './components/NavBarComponents';
import { useSearchParams } from 'react-router-dom';
import Footer from './components/Footer';
import EditCategoryComponent from './components/editeCategoryComponent';


function EditCategory() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("c")
  return (
        <>
      <NavBarComponent />
      <EditCategoryComponent
        id={id}
      />
      <Footer/>
      </>
  );
}

export default EditCategory;