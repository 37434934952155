import { useSearchParams } from 'react-router-dom';
import SetCategory from './components/SetCategory';
import { useEffect, useState } from 'react';
import { link } from './ultils/variabel';
import axios from 'axios';
import ProductList from './components/productList';
import NavBarComponent from './components/NavBarComponents';
import Footer from './components/Footer';


function Products() {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("query")
    const categoryQ = searchParams.get("c")
    const [category, setCategory] = useState(categoryQ ? categoryQ.toLowerCase() : "All") ;
    const [subCategory, setSubCategory] = useState("All");
    const [subCategory2, setSubCategory2] = useState("All");
    const [merek, setMerek] = useState("All");
    const product = query ? query.toLowerCase() : "All";
    const [limit, setLimit] = useState(0);
    const [resultP, setResultP] = useState([]);
    const [now, setNow] = useState(1);


    function setIndex(index){
      setNow(index)
    }

    useEffect(()=>{
      let cat = "";
      let subCat= "";
      let subCat2 = "";
      let mer = "";
      let q = "";
      let lim = now;
      if(category !== "All" || category === undefined){
          cat = category;
          
      }

      if(subCategory !== "All"){
          subCat = subCategory;
      }

      if(subCategory2 !== "All"){
          subCat2 = subCategory2;
      }
      if(merek !== "All"){
          mer = merek;
      }
      if(product !== "All"){
        q = product;
      }
      const fullLink = link + "admin/productSpecific?category="+cat+"&subCategory="+ subCat+"&subCategory2="+subCat2+ "&merek=" + mer +"&query="+q + "&lim="+lim;
      async function callAPI(){
        await axios.get(fullLink).then((rslt)=>{
          setResultP(rslt.data.result);
          setLimit(rslt.data.jumHal);
        });
      }
      callAPI();
    }, [category, subCategory, subCategory2, merek, now])
  return (
    <>
      <NavBarComponent />
      <div className='container mt-3'>
        <SetCategory 
        category={category}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        subCategory2={subCategory2}
        setSubCategory2={setSubCategory2}
        product={product}
        setCategory={setCategory}
        merek={merek}
        setMerek={setMerek}
        />

        <ProductList
        result={resultP}
        setIndex={setIndex}
        index={now}
        limit={limit}
        />
      </div>
      <Footer/>
      </>
  );
}

export default Products;
