import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Cookies from "universal-cookie";
import Button from 'react-bootstrap/esm/Button';
import { link } from '../ultils/variabel';
import sleep from '../ultils/sleep';
const InputCategory = ()=>{
    const [file, setFile] = useState();
    const [category, setCategory] = useState("");
    const [dis, setDis] = useState(false);
    const [flash, setFlash] = useState();
    const [penentu, setPenentu] = useState(false);
    const cookies = new Cookies();
    async function submit(event){
        let token = cookies.get("token")
        let data = {
            file : file,
            category : category,
            token : token,
        }
        event.preventDefault();
        try{
            
            setDis(true);
            await axios.put(link + "admin/addCategory", data, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then((data)=>{
                if(data.status == 200){
                    
                    sleep(2000).then(()=>{
                        window.location.reload(true)
                     })

                     let tmp = [];
                     tmp.push((<div key={"flash"} className="alert alert-success alert-dismissible fade show" role="alert">
                     <strong>Data Berhasil DI tambah</strong>
                     <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>))
                     setFlash(tmp)
                    setPenentu(true);
                }
              })
        }catch(error){
            setDis(false)
            event.preventDefault();
            console.log(error)
            let tmp = [];
            tmp.push((<div key={"flash"} className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Data Tidak Bisa Tertambah, Mohon Coba Lagi</strong>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>))
            setFlash(tmp)
            setPenentu(true)
        }
    }

    return(
        <div className='container mt-5 mb-5'>
            <h1 className='display-4 text-center mb-3' style={{ fontSize: "40px" }}>Add Category</h1>
            {penentu ? flash : ""}
    <Form onSubmit={submit}>
            <Form.Control
                type="file" 
                className='mb-5'
                required={true}
                onChange={(e)=>{
                    setFile(e.target.files[0])
                }} />
                <InputGroup className='mb-5'>
                <InputGroup.Text>Category  </InputGroup.Text>
                        <Form.Control 
                        required={true} 
                        onChange={(e)=>{
                            setCategory(e.target.value)
                        }}
                        aria-label="Nama" 
                        />
                </InputGroup>
        <Button variant="primary" type="submit">
            Submit
        </Button>
      </Form>
      </div>)
}

export default InputCategory;