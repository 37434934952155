import React, { useEffect, useState } from 'react';
import DropDown from './utils/dropdown';
import axios from 'axios';
import { link } from '../ultils/variabel';

const SetCategory =  (props)=>{
    // const [category, setCategory] = useState("All");
    const category = props.category;
    const setCategory = props.setCategory;

    // const [subCategory, setSubCategory] = useState("All");
    const subCategory = props.subCategory;
    const setSubCategory = props.setSubCategory;

    const subCategory2 = props.subCategory2;
    const setSubCategory2 = props.setSubCategory2;

    const merek = props.merek;
    const setMerek = props.setMerek;

    const [categoryResult, setCategoryResult] = useState([]);
    const [subCategoryResult, setSubCategoryResult] = useState([]);
    const [subCategoryResult2, setSubCategoryResult2] = useState([]);
    const [merekResult, setMerekResult] = useState([]);

    useEffect(()=>{
        let cat = "";
        let subCat= "";
        let subCat2 = "";
        let mer = "";
        if(category !== "All"){
            cat = category;
        }

        if(subCategory !== "All"){
            subCat = subCategory;
        }

        if(subCategory2 !== "All"){
            subCat2 = subCategory2;
        }
        if(merek !== "All"){
            mer = merek;
        }

        setCategory(cat !== "" ? cat : "All");
        setSubCategory(subCat !== "" ? subCat : "All");
        setSubCategoryResult2(subCat2 !== "" ? subCat2 : "All")
        setMerek(mer !== "" ? mer : "All")

        async function callAPI(){
            const temp = await axios.post(link+"categoryName", {
                category : cat,
                subCategory : subCat,
                subCategory2 : subCat2
            });
            setCategoryResult(temp.data.category);
            setSubCategoryResult(temp.data.subCategory);
            setSubCategoryResult2(temp.data.subCategory2);
            setMerekResult(temp.data.merek);
        }
        callAPI();
    },[category, subCategory, subCategory2, merek])
    return (
    <>
        <div className='container d-flex justify-content-around flex-wrap'>
        <DropDown 
            judul={category}
            awal={"Category"}
            event={setSubCategory}
            changeCategory={setCategory} 
            result={categoryResult}
            />
            <DropDown 
            judul={subCategory}
            awal={"Sub Category 1"}
            event={setSubCategory2}
            changeCategory={setSubCategory}
            result={subCategoryResult}
            />
            <DropDown 
            judul={subCategory2}
            awal={"Sub Category 2"}
            changeCategory={setSubCategory2}
            result={subCategoryResult2}
            />
            <DropDown 
            judul={merek}
            awal={"Merek"}
            changeCategory={setMerek}
            result={merekResult}
            />
        </div>
    </>);
}

export default SetCategory;