import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { link } from "../ultils/variabel";
import Cookies from "universal-cookie";
import Button from 'react-bootstrap/esm/Button';
import Swal from 'sweetalert2';
import sleep from "../ultils/sleep"

const EditCategory1Component = ({id})=> {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const [active, setActive] = useState(1);
    const [subCategory2, setSubCategory2] = useState([]);
    const [listTipe , setListTipe] = useState([]);
    const [jumlah, setJumlah] = useState(0);
    const [del, setDel] = useState([]);
    const [idc, setIdc] = useState([])

    async function updateActive(){
        let actv = active == 1 ? 0 : 1;
        let dat = {
            id : id,
            active : actv,
            token : token
        }
        let result = await axios.put(link + "admin/updateSubCategoryActive", dat);
        if(result.status == 200){
            Swal.fire(
                'Success',
                'Data Berhasil Di Ubah',
                'success'
              )
              sleep(1500).then(()=>{
                window.location.reload(true)
             })
        }
    }

    async function updateActiveSubCat(id, active){
        let actv = active == 1 ? 0 : 1;
        let dat = {
            id : id,
            active : actv,
            token : token
        }
        let result = await axios.put(link + "admin/updateSubCategory2Active", dat);
        if(result.status == 200){
            Swal.fire(
                'Success',
                'Data Berhasil Di Ubah',
                'success'
              )
              sleep(1500).then(()=>{
                window.location.reload(true)
             })
        }
    }

    useEffect(()=>{
        async function callAPI() {
            await axios.get(link + "admin/getSubCategory?category=" + id).then(data =>{
                if(data.status == 200){
                    let cat = data.data.category;
                    let cat2 = data.data.category2;
                    setActive(cat[0].active);
                    setIdc(cat[0].id)
                    setSubCategory2(cat2);
                    setJumlah(cat2.length == 0 ? 1 : cat2.length)
                }
            });

        }
        callAPI();
    }, [])

    useEffect(()=>{
        let sb2 = [];
        let temp = [];
        for (let index = 0; index < jumlah; index++) {
            sb2.push(subCategory2[index]);
            console.log(sb2)
            
            temp.push((
            <div key={"ipt"+index}>
                <input type="hidden" name={"key" + index} value={sb2[index] ? sb2[index].id : ""} />
            <InputGroup className="mb-3">
            <InputGroup.Text> Sub Category2  </InputGroup.Text>
            <Form.Control 
                required={true}
                defaultValue={sb2[index] ? sb2[index].subCategory2 : ""}
                name={"sb1"+index}
                aria-label="First name" 
            />
            {
                sb2[index] ? <Button
                style={{ 
                    marginRight : "15px"
                }}
                variant={sb2[index].active == 1 ? "danger" : "success"}
                onClick={()=>{updateActiveSubCat(sb2[index].subCategory2, sb2[index].active)}}
                >
                {sb2[index].active == 1 ? "non active" : "active"}
                </Button> : ""
            }
            </InputGroup>
            </div>))
        }

        setListTipe(temp)
    }, [jumlah])

    return (
        <div className='container mt-5 mb-5'>
            <h1 className='display-4 text-center mb-3' style={{ fontSize: "40px" }}>Edit Sub Category {id}</h1>
        <Form action={link + "admin/updateSubCategory"} method="post" encType='multipart/form-data'>
            <input type="hidden" name="jumlah" value={jumlah} />
            <input type="hidden" name="category" value={id} />
            <input type="hidden" name="del" value={del} />
            <input type="hidden" name='id' value={idc} />
                    {listTipe}
                    <div 
        className='mb-3 linked rounded'
        onClick={()=> {
            setJumlah(jumlah - 1);
            subCategory2.pop()
            let temp = listTipe.pop();
            let tmp = del;
            tmp.push(temp.props.children[0].props.value)
            setDel(tmp)
        }} 
        style={
            {
                background : "#f02724",
                textAlign : "center"
            }
        }>-</div>
        <div 
        className='mb-3 linked rounded'
        onClick={()=> {setJumlah(jumlah + 1)}} 
        style={
            {
                background : "#dfdfdf",
                textAlign : "center"
            }
        }>+</div>




        <Button
        style={{ 
            marginRight : "15px"
        }}
        variant={active == 1 ? "danger" : "success"}
        onClick={updateActive}
        >
        {active == 1 ? "non active" : "active"}
        </Button>
        <Button variant="primary" type="submit">
            Submit
        </Button>

                    
        </Form>
    </div>
    );
}

export default EditCategory1Component;