import React from 'react';
import { isMobile } from 'react-device-detect';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { link } from '../../ultils/variabel';


const ImageBanner = (props) => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 0 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    

    
    const listItems = props.link.map((rslt, inx) =>
            // Wrong! The key should have been specified here:
                <img
                    className="d-block w-100 linked"
                    onClick={()=>{window.location.href = rslt["goTo"];}}
                    key={inx} src={ link +  rslt["link"]}
                    alt="Third slide"
                />
          );

    return ( 
        <div className= {isMobile ? "mb-5" : "container mb-5" }>
          <Carousel
              showDots={true}
              infinite={true}
              autoPlay={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              autoPlaySpeed={2000}
              responsive={responsive}
              className="mt-5"
            >
              {listItems}
            </Carousel>
        </div>
       
     ); 
}

export default ImageBanner;