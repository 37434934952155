const Deskripsi = (props)=>{
    const info = props.info.split('\n').map((rslt, inx) => <p key={inx} className={'display-4'} style={{ fontSize: "14px" }}>{rslt}</p>)
    const deskripsi = props.deskripsi.split('\n').map((rslt, inx) => <p key={inx} className={'display-4'} style={{ fontSize: "14px" }}>{rslt}</p>)
    
    return (
        
            <>
                <p className='display-4' style={{  fontSize: "25px" }}> Deskripsi : </p>
                {deskripsi}
                <p className='display-4' style={{  fontSize: "25px" }}> Informasi mengenai product : </p>
                {info}
            </>
        
    )
}

export default Deskripsi;